import React from "react"
import Gallery, { Photo } from "react-photo-gallery"

const Bilderohnemodal = ({ photos }) => {
  const imageRenderer = ({
    index,
    onClick,
    margin,
    direction,
    left,
    top,
    key,
    photo,
  }) => (
    <div>
      <Photo
        className="smaler"
        key={key}
        index={index}
        photo={photo}
        left={left}
        top={top}
        onClick={onClick}
        margin={margin}
        direction={direction}
      />
      <small>
        <strong>{photo.titel}</strong>
      </small>
    </div>
  )

  return (
    <div>
      <Gallery photos={photos} renderImage={imageRenderer} />
    </div>
  )
}

export default Bilderohnemodal
