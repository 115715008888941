import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Title from "../components/Title"
import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"
import { Button, Card, Accordion, Figure } from "react-bootstrap"
import Bilderohnemodal from "../components/Bilderohnemodal"

const bilder = [
  {
    src: "/images/dpultdach.gif",
    width: 0.5,
    height: 0.5,
    titel: "Pultdach",
    alt: "Pultdach",
    caption: "Pultdach",
  },
  {
    src: "/images/dkrueppelwlmdach.gif",
    width: 0.5,
    height: 0.5,
    titel: "Krüppeldach",
    alt: "Krüppeldach",
    caption: "Krüppeldach",
  },
  {
    src: "/images/dmansarddach.gif",
    width: 0.5,
    height: 0.5,
    titel: "Mansarddach",
    alt: "Mansarddach",
    caption: "Mansarddach",
  },
  {
    src: "/images/dsatteldach.gif",
    width: 0.5,
    height: 0.5,
    titel: "Satteldach",
    alt: "Satteldach",
    caption: "Satteldach",
  },
  {
    src: "/images/dwalmdach.gif",
    width: 0.5,
    height: 0.5,
    titel: "Walmdach",
    alt: "Walmdach",
    caption: "Walmdach",
  },
  {
    src: "/images/dzeltdach.gif",
    width: 0.5,
    height: 0.5,
    titel: "Zeltdach",
    alt: "Zeltdach",
    caption: "Zeltdach",
  },
]

const Index = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Unsere Leistungen"
        description={data.site.siteMetadata.description}
      />

      <ServicesContainer>
        <Title title="Unsere " subtitle="Leistungen" />
        <hr className="services_separator" />
        <TextWrapper>
          <p>
            Nachfolgend haben wir Ihnen die{" "}
            <strong>wichtigsten Leistungen</strong> aufgeführt.
          </p>
          <p>
            Bitte{" "}
            <strong>klicken Sie auf eine orangefarbene Überschrift</strong>, um
            einen Bereich auf- beziehungsweise zuzuklappen:
          </p>
          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <h5>Schiefer</h5>
                </Accordion.Toggle>
                <p>
                  Hier beantworten wir einige Fragen zu diesem Naturprodukt,
                  zeigen Einsatzmöglichkeiten und Beispiele von Deckungsarten.
                </p>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <h4>Schiefer - Mehr als nur eine Dachverkleidung</h4>
                  <Figure>
                    <Figure.Image
                      width={160}
                      height={138}
                      alt="Altdeutsche Deckung"
                      src="/images/altdeu.jpg"
                    />
                  </Figure>
                  <p>
                    Schiefer ist ein Naturstein, der sich aufgrund seiner
                    Eigenschaften und Einsatzmöglichkeiten besonders gut zum
                    Eindecken eines Daches eignet.
                  </p>
                  <p>
                    Heute wird Schiefer vielseitig beim Hausbau verwendet, dabei
                    beschränkt sich dessen Verwendung nicht nur auf das Dach.
                    Auch zur Fassadenverkleidung oder Erkerverkleidung wird der
                    Schiefer häufig verwendet. Als Schieferplatten wird er auch
                    als dekorative Wegbefestigung eingesetzt, oder auch
                    außerhalb des Bauwesens, z. B. als Billardtisch-Platten.
                  </p>
                  <p>
                    Wir haben uns auf das Arbeiten mit Schiefer spezialisiert
                    und kennen die Besonderheiten dieses Baustoffes sehr gut.
                    Darum geben wir auch bei der Verarbeitung darauf acht, das
                    die Eigenschaften des Schiefers optimal erhalten bleiben,
                    nicht umsonst sind wir ein von der Firma Rathscheck
                    anerkannter Schieferdecker.
                  </p>
                  <h3>Vorteile des Schiefers</h3>
                  <ul>
                    <li>
                      {" "}
                      sehr lange Lebensdauer (z.B. Moselschiefer® ≥ 200 J.)
                    </li>

                    <li>
                      {" "}
                      äußerst wirtschaftlich im Verhältnis Anschaffungskosten =>
                      Lebensdauer
                    </li>

                    <li> sehr hohe Witterungsresistenz</li>

                    <li>
                      {" "}
                      bietet viele Gestaltungsmöglichkeiten, bedingt durch die
                      unterschiedlichen Deckungsarten oder auch dem Farbschiefer
                    </li>

                    <li> natürlicher Baustoff, umweltverträglich</li>
                  </ul>
                  <Figure>
                    <Figure.Image
                      width={160}
                      height={138}
                      alt="Ornament-Deckung, Motiv (zwei Vögel)"
                      src="/images/ornadeck1.jpg"
                    />
                    <Figure.Caption>
                      Ornament-Deckung, Motiv (zwei Vögel)
                    </Figure.Caption>
                  </Figure>
                  <h3>Woher kommt Schiefer?</h3>
                  <p>
                    Der aus Deutschland kommende Schiefer wird meist
                    Moselschiefer® oder Thüringer Schiefer® genannt. Hierbei
                    handelt es sich aber vielmehr um eine Qualitätsbezeichnung,
                    da z. B. der Moselschiefer bei Mayen in der Eifel abgebaut
                    wird. Dies sind die größten deutschen
                    Schiefergewinnungsstätten. Weitere befinden sich im
                    Sauerland und im Hunsrück. Aber auch aus dem Ausland wird
                    Schiefer nach Deutschland importiert, wie z. B. Spanien.
                  </p>

                  <Figure>
                    <Figure.Image
                      width={160}
                      height={138}
                      alt="Bogenschnitt-Deckung"
                      src="/images/bogdeck.jpg"
                    />
                    <Figure.Caption>Bogenschnitt-Deckung</Figure.Caption>
                  </Figure>
                  <h3>Ist Schiefer teuer?</h3>
                  <p>
                    Schiefer an sich wird nur hinsichtlich gewisser
                    Qualitätsmerkmale als gut oder schlecht bezeichnet, was
                    natürlich auch Preisunterschiede mit sich bringt. Vielmehr
                    ist bei den Kosten die Wahl der Deckungsart entscheidend,
                    eine exklusive und besondere Deckungsart ist beispielsweise
                    die Altdeutsche Deckung.
                  </p>
                  <Figure>
                    <Figure.Image
                      width={160}
                      height={138}
                      alt="Altdeutsche Deckung"
                      src="/images/altdeu_5.jpg"
                    />
                    <Figure.Caption>Altdeutsche Deckung</Figure.Caption>
                  </Figure>

                  <h3>Ist Schiefer immer dunkelfarbig?</h3>
                  <p>
                    Nein. Es gibt den sogenannten Farbschiefer. Dieser ist in
                    seinen Vorkommen recht selten. Verantwortlich für die
                    Farbgebung sind hierbei natürliche mineralische
                    Bestandteile. Demnach kann der Farbschiefer grün (Chlorit)
                    oder rot bis purpurn (Hämatit) erscheinen.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  <h5>Steildach</h5>
                </Accordion.Toggle>
                <p>
                  Allgemeine Informationen rund um das Steildach, sowie die
                  verbreitetsten Dachformen.
                </p>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <h4> Das Steildach - Die Krone Ihres Hauses</h4>
                  <p>
                    Das Steildach wird aufgrund seiner Formenvielfalt und vielen
                    Gestaltungsmöglichkeiten zurecht die Krone des Hauses
                    genannt, da es maßgeblich zum Gesamtbild Ihres Hauses
                    beiträgt.
                  </p>
                  <p>
                    Doch mehr als das harmonische Gesamtbild zählt die Funktion
                    des Steildaches, dies beinhaltet auch Faktoren wie
                    Wärmedämmung, Hinterlüftung oder Dampfsperre. Schließlich
                    bestimmen diese Faktoren auch das Wohnklima innerhalb einer
                    Dachwohnung. Daher ist eine genaue Kenntnis des
                    Zusammenspiels dieser Schichten für den Fachmann
                    selbstverständlich.
                  </p>
                  <p>
                    So schützt Sie das Steildach bei fachgerechtem Aufbau vor
                    Witterungseinflüssen und hilft, bei entsprechend
                    berücksichtigter Wärmedämmung, Energie zu sparen.
                  </p>
                  Mit einem Steildach haben Sie außerdem die Möglichkeit
                  zusätzlichen Wohnraum zu nutzen, ohne dass hierfür mehr
                  Grundstücksfläche benötigt wird, egal ob von Anfang an geplant
                  oder erst bei späterem Bedarf.
                  <p>
                    Die meistverwendeten Deckungsmaterialien eines Steildaches:
                  </p>
                  <ul>
                    <li> Dachziegel</li>

                    <li> Schiefer</li>

                    <li>Betondachsteine</li>

                    <li> Bitumenschindeln</li>
                  </ul>
                  <a href="https://www.braas.de/">
                    <Figure>
                      <Figure.Image
                        width={80}
                        height={69}
                        alt="Altdeutsche Deckung"
                        src="/images/braas.gif"
                      />
                    </Figure>
                  </a>
                  <br />
                  <a href="https://www.erlus.com/">
                    <Figure>
                      <Figure.Image
                        width={80}
                        height={69}
                        alt="Altdeutsche Deckung"
                        src="/images/erluslogo.gif"
                      />
                    </Figure>
                  </a>
                  <p>
                    Welche Deckung oder Dachform Sie letztendlich wählen, hängt
                    oft von den Kosten ab oder wird durch die Bauverordnung
                    vorgegeben. Wir helfen Ihnen gerne und beraten Sie bei der
                    Gestaltung Ihres Daches.
                  </p>
                  <h5>Dachformen</h5>
                  <div className="smaler">
                    <Bilderohnemodal photos={bilder} />
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  <h5>Flachdach</h5>
                </Accordion.Toggle>
                <p>
                  Wissenswertes zum Thema Flachdach und dessen Besonderheiten.
                </p>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <h4>Das Flachdach - Grau oder Grün?</h4>

                  <p>
                    Das Flachdach leidet heute immer noch unter dem Vorurteil,
                    dass ein solches Dach nicht dicht zu kriegen sei oder über
                    kurz oder lang undicht wird.
                  </p>

                  <p>
                    Dies ist vor allem auf das geringe bis nicht vorhandene
                    Gefälle ( 0°-20° Neigung Flachdach, über 20° Neigung
                    Steildach ) und die verwendeten Materialien vieler früherer
                    Flachdächer zurückzuführen. So kann es zum Beispiel bei
                    geringen Neigungen, durch Unebenheiten in der Oberfläche
                    oder Verstärkungen, zu behindertem Wasserabfluss oder
                    Pfützenbildung kommen. Dies ist jedoch bei entsprechend
                    fachgerechter Abdichtung gar kein Problem.
                  </p>

                  <p>
                    Dieses "Problem" kann aber, durch ein entsprechendes Gefälle
                    im Flachdach und möglichst kurzen Entwässerungswegen, schon
                    bei der Planung größtenteils umgangen werden. Auch trägt die
                    Verbesserung der heutigen Werkstoffe maßgeblich dazu bei,
                    dass das Flachdach wieder beliebter wird.
                  </p>

                  <p>
                    Ein Flachdach muss laut Richtlinie wasserdicht sein, ein
                    Steildach dagegen "nur" regensicher. Und gerade deshalb ist
                    es wichtig, dass Sie die Abdichtung Ihres Daches in die
                    kompetenten Hände eines Fachmannes legen, damit es nicht zu
                    bösen Überraschungen kommt.
                  </p>

                  <p>
                    Flachdächer werden heute häufig mit geklebten oder
                    geschweißten Bitumenbahnen abgedichtet. Auch Folien aus
                    verschiedenen Kunststoffen werden als Bahnen, oder
                    großflächig als Planen, zum Abdichten verwendet.
                    Grundsätzlich besteht eine Abdichtung aus mehreren
                    Schichten. Zwischen diesen Schichten wird die Wärmedämmung
                    verlegt.
                  </p>

                  <p>
                    Eine weitere Möglichkeit der Flachdach-Deckung, besteht in
                    der Begrünung der vorhandenen Fläche. Dies sollte aber
                    bereits schon in der Planung berücksichtigt werden, da
                    hierfür die Dachkonstruktion und das Abdichtungsmaterial
                    entsprechend geeignet sein müssen. Die steigende Beliebtheit
                    dieser Variante, liegt vor allem an dem damit verbundenen
                    Umweltgedanken. Zum einen kann eine Begrünung zur Senkung
                    der Heizkosten beitragen, ist aber auch gleichzeitig ein
                    gewisser Ausgleich für die durch den Bau selbst
                    verlorengegangene Vegetation. Und gerade ein Flachdach ist
                    für diese Art der Deckung sehr geeignet.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  <h5>Fassade</h5>
                </Accordion.Toggle>
                <p>
                  Verkleidung der Fassade mit unterschiedlichen Materialien wie
                  Schiefer, Paneelen aus Faserzement, Holz oder Metall
                  einschließlich Unterkonstruktion und Dämmung.
                </p>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <h4>
                    Die Fassade - Schnittstelle zwischen Innen- und Außenraum!
                  </h4>

                  <p>
                    Wärmegedämmte, hinterlüftete Außenwandbekleidungen tragen
                    entscheidend zur Energieeinsparung bei. Die erforderlichen
                    Investitionen sind durch die eingesparten Energiekosten
                    schnell refinanziert.
                  </p>

                  <p>
                    Faserzement-Fassadenpaneele (Cedral von Eternit) verbinden
                    die Optik von Holz mit der Dauerhaftigkeit von Faserzement.
                    Das umweltfreundliche Paneel gibt es in vielen Farben mit
                    Holzmaserung oder glatter Oberfläche. Sie benötigen keine
                    regelmäßigen Anstriche, sind extrem frost-und
                    witterungsbeständig.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                  <h5>Dachflächenfenster</h5>
                </Accordion.Toggle>
                <p>
                  Einige Informationen zum Einbau von Dachfenstern und deren
                  Funktion.
                </p>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <h4> Dachflächenfenster - Freie Bahn für das Sonnenlicht</h4>
                  <p>
                    Für den Einbau oder die Nachrüstung eines
                    Dachflächenfensters sind wir für Sie ein kompetenter
                    Ansprechpartner.
                  </p>
                  <p>
                    Das Dachfenster fachgerecht einzubauen trägt wesentlich zur
                    Werterhaltung und Lebensdauer der angebrachten Komponente
                    bei. Gerade bei einer Nachrüstung ist zu beachten, dass nach
                    dem Einbau die Eigenschaften des Daches, wie Wärmedämmung
                    oder der Schutz vor Witterungseinflüssen, gewährleistet sind
                    und wiederhergestellt werden.
                  </p>
                  <Figure>
                    <Figure.Image
                      align="center"
                      width={80}
                      height={69}
                      alt="Funktionsskizze Schwingfenster"
                      src="/images/schwenken.gif"
                    />
                    <Figure.Caption>
                      Funktionsskizze Schwingfenster
                    </Figure.Caption>
                  </Figure>
                  <p>
                    Dachflächenfenster gibt es in den verschiedensten Varianten.
                    Da gibt es den Rahmen, der meist aus Holz oder Kunststoffen
                    besteht, die verschiedenen Öffnungsvarianten, wie
                    Klappfenster, Schwingfenster oder einer Kombination aus
                    diesen, dem Klapp-Schwing-Fenster, und natürlich die
                    unterschiedlichen, meist vorgegebenen Größen des Fensters.
                  </p>
                  <Figure>
                    <Figure.Image
                      align="center"
                      width={80}
                      height={69}
                      alt="unktionsskizze Klappfenster"
                      src="/images/klappen.gif"
                    />
                    <Figure.Caption>
                      Funktionsskizze Klappfenster
                    </Figure.Caption>
                  </Figure>
                  <p>
                    Ein weiterer Entscheidungspunkt für ein Dachfenster ist der
                    verbesserte Lichteinfall. Dieser ist hier z. B. höher als
                    bei einer senkrechten Gaube. Doch auch für ein verbessertes
                    Wohnklima ist der natürliche Lichteinfall ein Argument, da
                    es bei längerem Aufenthalt unter künstlichem Licht zu
                    Konzentrationsschwächen und Müdigkeit kommen kann.
                  </p>
                  <p>
                    Für welches Variation Sie sich entscheiden liegt ganz bei
                    Ihnen, doch wir helfen Ihnen gerne eine zufriedenstellende
                    Lösung zu finden.
                  </p>
                  <p>
                    Wir verwenden Qualitäts-Dachfenster von
                  </p>
                  <a href="https://www.velux.de">
                    <Figure>
                      <Figure.Image
                        width={80}
                        height={69}
                        alt="Velux"
                        src="/images/veluxlogo.gif"
                      />
                    </Figure>
                  </a>
                  <br />
                  <a href="https://www.roto-store.de">
                    <Figure>
                      <Figure.Image
                        width={80}
                        height={69}
                        alt="Roto"
                        src="/images/rotologo.gif"
                      />
                    </Figure>
                  </a>
                  <p>Verwenden Sie gerne unseren <a href="/konfigurator">VELUX Dachfenster-Konfigurator</a>.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                  <h5>Solartechnik</h5>
                </Accordion.Toggle>
                <p>
                  Antworten zu Fragen über Solartechnik, deren
                  Einsatzmöglichkeiten und Wissenswertes.
                </p>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <h4> Solartechnik - Das Kraftwerk auf dem Dach</h4>

                  <p>
                    Solartechnik, die umweltfreundliche Energiegewinnung aus dem
                    Licht der Sonne, ist in den Augen vieler Menschen leider
                    immer noch "unrentabel" und "in Deutschland doch gar nicht
                    möglich".
                  </p>
                  <p>
                    Dabei ist vielen einfach nicht bewusst, dass die Nutzung der
                    Sonnenenergie, bei richtiger Beratung und Planung, schon
                    lange nicht mehr ein Fass ohne Boden sein muss.
                  </p>
                  <p>
                    In Deutschland scheint die Sonne im Jahresdurchschnitt ca.
                    1.700 Stunden. Dies ist ausreichend, um Solaranlagen
                    langfristig wirtschaftlich zu betreiben.
                  </p>
                  <p>
                    Natürlich ist die Investition in Solartechnik auch eine
                    langfristige Kapitalanlage. Die Amortisation der Kosten für
                    eine Solaranlage, durch die damit zu erzielenden
                    Einsparungen bei den Stromkosten oder auch den Heizkosten,
                    kann, je nach Nutzung und Auslastung, ca. 15 Jahre oder mehr
                    betragen.
                  </p>
                  <p>
                    Wir beraten Sie und helfen Ihnen bei der Planung Ihrer
                    Solaranlage. Nehmen Sie mit uns Kontakt auf.
                  </p>
                  <h5>Welche Vorteile hat eine Solaranlage?</h5>
                  <ul>
                    <li> Umweltfreundliche Energiegewinnung</li>

                    <li> Lange Lebensdauer</li>

                    <li>Geringe Betriebskosten</li>

                    <li> Reduzierung des CO2 Ausstoßes</li>

                    <li>
                      {" "}
                      Einsparung von fossilen Energiestoffen, wie Öl, Kohle und
                      Gas
                    </li>
                  </ul>
                  <h5>Welche Einsatzmöglichkeiten habe ich?</h5>
                  <ul>
                    <li>Anwendbar in ganz Deutschland</li>
                    <li>
                      Einspeisung der gewonnenen Energie in den privaten
                      Stromhaushalt oder zur Wärmegewinnung
                    </li>
                    <li>
                      Einspeisung der gewonnenen Energie ins öffentliche
                      Stromnetz (subventioniert)
                    </li>
                    <li>Einsetzbar sowohl in Neubauten wie auch Altbauten</li>
                  </ul>
                  <h5>Unterschied zwischen Solarthermie und Photovoltaik</h5>

                  <p>
                    Solarthermie, auch bekannter als Solarwärme, ist nur eine
                    von zahlreichen Möglichkeiten, die Sonnenenergie zu nutzen.
                    Die Funktion einer solarthermischen Anlage besteht darin,
                    das Sonnenlicht in Wärme umzuwandeln. Bei der Photovoltaik
                    hingegen, wird das Licht der Sonne zur Stromerzeugung
                    verwendet und mit Hilfe von Solarzellen Strom (s.u.)
                    erzeugt.
                  </p>
                  <h5>Was ist Photovoltaik? Oder wie wird aus Licht Strom?</h5>

                  <p>
                    Photovoltaik bezeichnet die Umwandlung des Lichts in Strom
                    durch die Solarzellen. Eine Solarzelle ist auf der Ober- und
                    Unterseite speziell beschichtet. Durch Lichteinfall
                    entstehen Ladungsträger, die, je nach Ladung (positiv oder
                    negativ), zur Ober- oder Unterseite abgeleitet werden.
                    Dadurch entsteht ähnlich einer Batterie, ein Plus- und ein
                    Minuspol, an die man nun einen Stromverbraucher anschließen
                    kann.
                  </p>
                  <h5>Ist eine Montage auf dem Dach nicht schwierig?</h5>
                  <p>
                    Nein, da der Fachmann speziell dafür ausgebildet ist. Die
                    Montage von Solaranlagen ist ohne Probleme auf Steil- und
                    Flachdächern möglich. Ebenso arbeiten bei der Montage, der
                    Monteur und der Elektriker Hand in Hand, so dass
                    Schwierigkeiten bei der weiteren Verarbeitung ausgeschlossen
                    werden. Bei frühzeitiger Planung und Ausführung durch den
                    Dachdecker, wird die Anlage sogar bereits in das Dach
                    integriert, im Gegensatz zur "aufgesetzten" Lösung.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="6">
                  <h5>Wartung, Reparatur, Service</h5>
                </Accordion.Toggle>
                <p>Wenn das Dach auf einmal nicht mehr dicht ist...</p>
              </Card.Header>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <h4> Wartung - Reparatur - Service</h4>
                  <p>
                    Witterungseinflüsse wie Sturm, Hagel oder saurer Regen,
                    setzen im Laufe der Jahre selbst den beständigsten
                    Materialen zu und beeinträchtigen so die Schutzfunktion
                    eines Daches.
                  </p>
                  <p>
                    Egal ob es sich um abgedeckte Ziegel oder eine verstopfte
                    Dachrinne handelt, wir stehen Ihnen auch dann hilfreich zur
                    Seite und bieten Ihnen unter anderem folgende Leistungen,
                    wie
                  </p>
                  <ul>
                    <li>
                      Austausch von beschädigten Eindeckungsmaterialien wie:
                      <ul>
                        <li>Schiefer</li>
                        <li>Dachplatten</li>
                        <li>Ziegel</li>
                        <li>Pfannen, etc.</li>
                      </ul>
                    </li>
                    <li>
                      Reinigung, Prüfung und Reparatur von Dachrinnen und
                      Regenfallrohren
                    </li>
                    <li>Wartung und Säuberung von Flachdächern</li>
                    <li>Austausch von schadhaften Dachflächenfenstern</li>
                  </ul>
                  <p>
                    Diese Maßnahmen sind häufig notwendig, um die Funktionalität
                    Ihres Daches wiederherzustellen und die eigentliche
                    Schutzfunktion zu gewährleisten.
                  </p>
                  <p>
                    Zögern Sie nicht, uns im Schadensfall zu kontaktieren, wir
                    helfen Ihnen gerne weiter.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </TextWrapper>
      </ServicesContainer>
    </Layout>
  )
}

export const getIndexData = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
const ServicesContainer = styled.section`
  padding-top: 3rem;

  button.btn-link {
    color: var(--primaryColor);
  }

  button.btn-link:hover {
    text-decoration: none;
    color: var(--mainBlack);
  }

  .services_separator_margin {
    width: 20%;
    margin: 3rem auto;
  }

  .services_separator {
    width: 20%;
    margin: 0 auto;
  }
`

const TextWrapper = styled.div`
  margin-top: 1rem;
  padding: 1rem;

  ul {
    padding-left: 1rem;
  }
  @media (min-width: 780px) {
    padding: 4rem;
    margin-top: 4rem;
  }
`

export default Index
